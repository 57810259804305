import axios from 'axios';

export default class PacienteService {

	getPacientes() {
		//return axios.get('assets/layout/data/pacientes.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/pacientes`;
		const url = `/api/pacientes`;
		return axios.get(url).then(res=>res.data.data);
	}

	createPacientes(pacientes) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/pacientes`;		
		const url = `/api/pacientes`;		
		return axios.post(url,pacientes).then(res=>res.data.data);
	}
	
	updatePacientes(pacientes) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/pacientes/${pacientes.id_paciente}`;
		const url = `/api/pacientes/${pacientes.id_paciente}`;
		return axios.put(url,pacientes).then(res=>res.data.data);
	}
	
	deletePacientes(pacientes) {
		//return axios.get('assets/layout/data/usuarios.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/pacientes/${pacientes.id_paciente}`;
		const url = `/api/pacientes/${pacientes.id_paciente}`;
		return axios.delete(url).then(res=>res.data.data);
	}
	
	getPacientesExport() {
		//return axios.get('assets/layout/data/pacientes.json').then(res => res.data.data);
		//const url = ` https://sam.apicontrol.website/api/api/pacientesexport`;
		const url = `/api/pacientesexport`;
		return axios.get(url).then(res=>res.data.data);
	}
}
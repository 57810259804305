<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<Button label="Nuevo Paciente..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
						<Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" />
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedPacientes || !selectedPacientes.length" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="pacientes" v-model:selection="selectedPacientes" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} pacientes">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Catálogo de Pacientes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<!-- <Column field="id_paciente" header="Id" :sortable="true"></Column> -->
					<Column field="nombre" header="Nombre" :sortable="true"></Column>
                    <Column field="apellido_paterno" header="Paterno" :sortable="true"></Column>
                    <Column field="apellido_materno" header="Materno" :sortable="true"></Column>
                    <Column field="sexo" header="Sexo" :sortable="true"></Column>
					<Column field="fecha_nacimiento" header="Nacimiento" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormat(slotProps.data.fecha_nacimiento)}}
                        </template>
					</Column>
                    <Column field="fecha_nacimiento" header="Edad" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormatEdad(slotProps.data.fecha_nacimiento)}}
                        </template>					
					</Column>
                    <Column field="doctor" header="Doctor" :sortable="true"></Column>
                    <Column field="created_at" header="Alta" :sortable="true">
						<template #body="slotProps">
                                    {{computedDatesFormatAlta(slotProps.data.created_at)}}
                        </template>
					</Column>
					<Column field="vendedor" header="Vendedor" :sortable="true"></Column>
					<Column field="origen" header="Origen" :sortable="true"></Column>
                    <Column field="activo" header="Activo" :sortable="true">
						<template #body="slotProps">
                                    {{turno(slotProps.data.activo)}}
                        </template>
					</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editPaciente(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePaciente(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="pacienteDialog" :style="{width: '550px'}" header="Paciente" :modal="true" class="p-fluid">
					<div class="p-field">
						<label for="nombre">Nombre del Paciente *</label>
						<InputText id="nombre" v-model.trim="paciente.nombre" required="true" autofocus :class="{'p-invalid': submitted && !paciente.nombre}" />
						<small class="p-invalid" v-if="submitted && !paciente.nombre">El nombre del paciente es requerido</small>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="apellidoPaterno">Apellido Paterno *</label>
							<InputText id="apellidoPaterno" v-model.trim="paciente.apellido_paterno" required="true" autofocus :class="{'p-invalid': submitted && !paciente.apellido_paterno}" />
							<small class="p-invalid" v-if="submitted && !paciente.apellido_paterno">El apellido paterno del paciente es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="apellidoMaterno">Apellido Materno *</label>
							<InputText id="apellidoMaterno" v-model.trim="paciente.apellido_materno" required="true" autofocus :class="{'p-invalid': submitted && !paciente.apellido_materno}" />
							<small class="p-invalid" v-if="submitted && !paciente.apellido_materno">El apellido materno del paciente es requerido</small>
						</div>
					</div>

					<div class="p-field">
						<label for="doctor">Doctor *</label>
						<Dropdown id="doctor" name="doctor" v-model="paciente.id_doctor" :options="doctores" optionLabel="nombre" placeholder="Selecionar un doctor ..."  autofocus :class="{'p-invalid': submitted && !paciente.id_doctor}" />
						<small class="p-invalid" v-if="submitted && !paciente.id_doctor">El doctor es requerido</small>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="sexo">Sexo *</label>							
							<SelectButton id="sexo" v-model="paciente.sexo" :options="options" autofocus :class="{'p-invalid': submitted && !paciente.sexo}" />
							<small class="p-invalid" v-if="submitted && !paciente.sexo">El sexo es requerido</small>
						</div>
						<div class="p-field p-col">
							<label for="fechaNacimiento">Fecha de Nacimiento *</label>
							<InputMask id="fechaNacimiento" mask="9999-99-99" v-model="paciente.fecha_nacimiento" placeholder="9999-99-99" slotChar="yyyy-mm-dd" autofocus :class="{'p-invalid': submitted && !paciente.fecha_nacimiento}" />
							<small class="p-invalid" v-if="submitted && !paciente.fecha_nacimiento">La fecha nacimiento es requerido</small>
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="celular">Celular</label>
							<InputText id="celular" v-model="paciente.celular" />
						</div>
						<div class="p-field p-col">
							<label for="email">Email</label>
							<InputText id="email" v-model.trim="paciente.email" />
						</div>
					</div>

					<div class="p-formgrid p-grid">
						<div class="p-field p-col">
							<label for="doctor">Vendedor</label>
							<Dropdown id="vendedor" name="vendedor" v-model="paciente.id_vendedor" :options="vendedores" optionLabel="nombre" placeholder="Selecionar un vendedor ..."  autofocus :class="{'p-invalid': submitted && !paciente.id_vendedor}" />
							<!--<small class="p-invalid" v-if="submitted && !paciente.id_doctor">El doctor es requerido</small>-->
						</div>
						<div class="p-field p-col">
							<label for="doctor">Origen</label>
							<Dropdown id="origen" name="origen" v-model="paciente.id_origen" :options="origen" optionLabel="nombre" placeholder="Selecionar un origen ..."  autofocus :class="{'p-invalid': submitted && !paciente.id_vendedor}" />
						</div>
					</div>

                    <div class="p-field-checkbox">
                        <Checkbox id="activo" name="activo" :binary="true"  v-model="paciente.activo" />
                        <label for="activo">Activo</label>
                    </div>

					<template #footer>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="savePaciente" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePacienteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="paciente">¿Desea eliminar el paciente <b>{{paciente.nombre}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePacienteDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deletePaciente" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deletePacientesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="paciente">¿Desea eliminar los pacientes seleccionados?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePacientesDialog = false"/>
						<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteSelectedPacientes" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import PacienteService from '../service/PacienteService';
import DoctorService from '../service/DoctorService';
import UsuarioService from '../service/UsuarioService';
import VendedorService from '../service/VendedorService';
import OrigenService from '../service/OrigenService';
import moment from 'moment';
import XLSX from 'xlsx'
import Cookies from "js-cookie"
window.Cookies = Cookies

export default {
	data() {
		return {
			pacientes: null,
			pacienteDialog: false,
			deletePacienteDialog: false,
			deletePacientesDialog: false,
			paciente: {},
			selectedPacientes: null,
			filters: {},
			submitted: false,
			dropdownValues: [
				{doctor: 'Jesús Molina Mendoza'},
				{doctor: 'Felipe Macías López'},
				{doctor: 'Marcela Quiroz Sánchez'},
				{doctor: 'Mario Moreno López'},
			],
			options: ['M', 'F'],
			doctores: null,
			vendedores: null,
			origen: null,
			id_doctor:null,
			PacientePost:[],
			email:null,
			celular:null,
			OptionDoctor:null,
			OptionVendedor:null,
			OptionOrigen:null,
			PacienteExpor:null,
			useconver:null,
			userdata:null,
			MuestrasActivas:null,
			NombreDoctExport:null,
			usuarios:null,
			UsuarioReg:null,
			UsuarioMod:null,
		}
	},
	pacienteService: null,
	doctorService: null,
	usuarioService: null,
	created() {
		this.pacienteService = new PacienteService();
		this.doctorService = new DoctorService();
		this.usuarioService = new UsuarioService();
		this.vendedorService = new VendedorService();
		this.origenService = new OrigenService();
	},
	mounted() {
		this.pacienteService.getPacientes().then(data => this.pacientes = data);
		this.doctorService.getDoctores().then(data => this.doctores = data);
		this.vendedorService.getVendedor().then(data => this.vendedores = data);
		this.origenService.getOrigen().then(data => this.origen = data);
		this.pacienteService.getPacientesExport().then(data => {this.MuestrasActivas = data.data2});
		this.usuarioService.getUsuarios().then(data => {
			this.usuarios = data			
		});
	},
	methods: {
		openNew() {
			this.paciente = {};
			this.submitted = false;
			this.pacienteDialog = true;
			this.paciente.activo=true;
		},
		hideDialog() {
			this.pacienteDialog = false;
			this.submitted = false;
		},
		savePaciente() {
			this.submitted = true;
			this.useconver = Cookies.get("user");
           	if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)	
				console.log(this.userdata);											 
			}
			if (this.paciente.nombre.trim()) {
				if (this.paciente.id_paciente>0) {

					this.PacientePost.push({
						"id_paciente":this.paciente.id_paciente,
						"apellido_materno":this.paciente.apellido_materno,
						"apellido_paterno":this.paciente.apellido_paterno,
						"fecha_nacimiento":this.paciente.fecha_nacimiento,
						"nombre":this.paciente.nombre,
						"sexo":this.paciente.sexo,
						"activo":this.paciente.activo,
						"id_doctor":this.paciente.id_doctor.id_doctor,
						"id_vendedor":this.paciente.id_vendedor.id_vendedor,
						"id_origen":this.paciente.id_origen.id_origen,
						"celular":this.paciente.celular,
						"email":this.paciente.email, 
						"id_usuario_mod":this.userdata[0].id_usuario,
					});
					 
					this.pacienteService.updatePacientes(this.PacientePost[0]).then(document.location.reload());
					this.PacientePost=[];
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Paciente actualizado', life: 3000});
					 			 
					
				}
				else {
				 
					if( typeof this.paciente.celular != 'undefined'){
						this.celular=this.paciente.celular;
					}

					if( typeof this.paciente.email != 'undefined'){
						this.email=this.paciente.email;
					}

					this.PacientePost.push({
						"apellido_materno":this.paciente.apellido_materno,
						"apellido_paterno":this.paciente.apellido_paterno,
						"fecha_nacimiento":this.paciente.fecha_nacimiento,
						"nombre":this.paciente.nombre,
						"sexo":this.paciente.sexo,
						"activo":this.paciente.activo,
						"id_doctor":this.paciente.id_doctor.id_doctor,
						"celular":this.celular,
						"email":this.email,
						"id_vendedor":this.paciente.id_vendedor.id_vendedor,
						"id_origen":this.paciente.id_origen.id_origen,
						"id_usuario_reg":this.userdata[0].id_usuario,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});

					 
					this.pacienteService.createPacientes(this.PacientePost[0]).then();
					this.$toast.add({severity:'success', summary: 'Successful', detail: 'Paciente creado', life: 3000});
					this.PacientePost=[];					 
					 
					document.location.reload();
				}

				this.pacienteDialog = false;
				 
			}
		},
		editPaciente(paciente) {
			console.log(paciente)
			this.paciente = {...paciente};
			this.pacienteDialog = true;
			this.OptionDoctor=this.paciente.id_doctor
			this.OptionVendedor=this.paciente.id_vendedor
			this.OptionOrigen=this.paciente.id_origen

			for(let i in this.doctores){
				if(this.OptionDoctor==this.doctores[i].id_doctor){
					this.paciente.id_doctor = this.doctores[i];
				}                  	
			} 

			for(let i in this.vendedores){
				if(this.OptionVendedor==this.vendedores[i].id_vendedor){
					this.paciente.id_vendedor = this.vendedores[i];
				}                  	
			} 
			
			for(let i in this.origen){
				if(this.OptionOrigen==this.origen[i].id_origen){
					this.paciente.id_origen = this.origen[i];
				}                  	
			} 


		},
		confirmDeletePaciente(paciente) {
			this.paciente = paciente;
			this.deletePacienteDialog = true;
		},
		deletePaciente() {
			var validateFecha=true;
			this.useconver = Cookies.get("user");
			if(this.useconver != null || this.useconver !="null"){
				this.userdata=JSON.parse(this.useconver)												 
			}

			
			// this.pacientes = this.pacientes.filter(val => val.id !== this.paciente.id);
			this.deletePacienteDialog = false;
			this.PacientePost.push({
						"id_paciente":this.paciente.id_paciente,
						"apellido_materno":this.paciente.apellido_materno,
						"apellido_paterno":this.paciente.apellido_paterno,
						"fecha_nacimiento":this.paciente.fecha_nacimiento,
						"nombre":this.paciente.nombre,
						"sexo":this.paciente.sexo,
						"activo":0,
						"id_doctor":this.paciente.id_doctor.id_doctor,
						"celular":this.paciente.celular,
						"email":this.paciente.email,
						"id_vendedor":this.paciente.id_vendedor.id_vendedor,
						"id_origen":this.paciente.id_origen.id_origen,
						"id_usuario_mod":this.userdata[0].id_usuario,
					});

			for(var i in this.MuestrasActivas)
			{
				console.log(this.MuestrasActivas[i]);
				if(this.paciente.id_paciente==this.MuestrasActivas[i].id_paciente)
				{ 
					validateFecha=false;
				}                  	
			} 
			if(validateFecha==true){
				this.pacienteService.updatePacientes(this.PacientePost[0]).then();
				// this.paciente = {};
				// this.pacienteService.deletePacientes(this.paciente).then();
				this.$toast.add({severity:'success', summary: 'Successful', detail: 'Paciente eliminado', life: 3000});
				document.location.reload();
			}else
			{
				this.$toast.add({severity:'warn', summary: 'Mensaje', detail: 'No se puede eliminar.El paciente tiene muestras asignadas', life: 3000});
			}		 
		
			 
			 
			
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.pacientes.length; i++) {
				if (this.pacientes[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.pacientes.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deletePacientesDialog = true;
		},
		deleteSelectedPacientes() {
			this.pacientes = this.pacientes.filter(val => !this.selectedPacientes.includes(val));
			this.deletePacientesDialog = false;
			this.selectedPacientes = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Pacientes eliminados', life: 3000});
		},
		turno(value){
			if(value ==1){
				return 'Activo'
			}else{
				return 'Inactivo'
			}
		},
		computedDatesFormatAlta(value) {
                return moment(value).format('DD/MM/YYYY hh:mm A')
		},
		computedDatesFormat(value) {
                return moment(value).format('DD/MM/YYYY')
		},
		computedDatesFormatEdad(value) {
				var d =new Date();
				var fecha1 = moment(value);
				var fecha2 = moment(d);
				 
				console.log(fecha1,fecha2)
                return fecha2.diff(fecha1, 'years');
		},
		download : function() {
			this.pacienteService.getPacientesExport().then(data => {
			
			this.PacienteExpor = data.pacientes				 
			
			for(var i in  this.PacienteExpor)
			{ 
				for(var d in this.doctores){
					if(data.pacientes[i].id_doctor == this.doctores[d].id_doctor)
					{
						this.PacienteExpor[i].id_doctor=this.doctores[d].nombre;
					}
				}

				for(var us in this.usuarios){
					if(data.pacientes[i].id_usuario_reg == this.usuarios[us].id_usuario)
					{
						this.PacienteExpor[i].id_usuario_reg=this.usuarios[us].nombre+" "+this.usuarios[us].apellido_paterno+" "+this.usuarios[us].apellido_materno;
					}
				}

				for(var us1 in this.usuarios){
					if(data.pacientes[i].id_usuario_mod == this.usuarios[us1].id_usuario)
					{
						this.PacienteExpor[i].id_usuario_mod=this.usuarios[us1].nombre+" "+this.usuarios[us1].apellido_paterno+" "+this.usuarios[us1].apellido_materno;
					}
				}

				for(var v in this.vendedores){
					if(data.pacientes[i].id_vendedor == this.vendedores[v].id_vendedor)
					{
						this.PacienteExpor[i].id_vendedor=this.vendedores[v].nombre;
					}
				}
				for(var o in this.origen){
					if(data.pacientes[i].id_origen == this.origen[o].id_origen)
					{
						this.PacienteExpor[i].id_origen=this.origen[o].nombre;
					}
				}
			}


				if(this.PacienteExpor != null){
				const data = XLSX.utils.json_to_sheet(this.PacienteExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoPaciente.xlsx')
				}
			});
	
		},

	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.paciente-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .paciente-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.paciente-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
